import Vue from 'vue';
import VueRouter from 'vue-router';
import Intro from '@/views/Intro';
import Home from '@/views/Home';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/get-started',
        name: 'Get Started',
        component: Intro,
    },
    {
        path: '/sign-up',
        name: 'Sign Up',
        component: () => import('../views/SignUp.vue'),
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard/Dashboard.vue'),
        children: [
            {
                path: '/',
                name: 'welcome',
                component: () => import('../views/dashboard/Welcome'),
            },
            {
                path: '/dashboard/package',
                name: 'Package',
                component: () => import('../views/dashboard/Package'),
            },
            {
                path: '/dashboard/add-ons',
                name: 'Add Ons',
                component: () => import('../views/dashboard/AddOns'),
            },
            {
                path: '/dashboard/services',
                name: 'Services',
                component: () => import('../views/dashboard/Services'),
            },
            {
                path: '/dashboard/review',
                name: 'Review',
                component: () => import('../views/dashboard/Review'),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
