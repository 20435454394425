<template>
    <v-app>
        <v-list flat>
            <v-subheader>Url links</v-subheader>
            <v-list-item-group color="primary">
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    target="_blank"
                    @click="linkTo(item.link)"
                >
                    <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-app>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    data: () => {
        return {
            items: [
                { text: 'Get Started', link: 'get-started' },
                { text: 'Sign Up', link: 'sign-up' },
                //{ text: 'Login', link: 'login' },
                { text: 'Dashboard', link: 'dashboard' },
                { text: 'Package', link: 'dashboard/package' },
                { text: 'Add ons', link: 'dashboard/add-ons' },
                { text: 'Services', link: 'dashboard/services' },
                { text: 'Review', link: 'dashboard/review' },
            ],
        };
    },
    methods: {
        linkTo(path) {
            let route = this.$router.resolve({ path });
            window.open(route.href, '_blank');
        },
    },
};
</script>
