<template>
    <div class="intro-view">
        <div class="intro-view__container">
            <div class="intro-view__col">
                <div class="intro-view__instructions">
                    <img class="tenant-logo" src="../assets/images/tenant-logo.svg" alt="" />
                    <h2 class="headline headline--h1">
                        We’ve made it easy!
                    </h2>
                    <p>Start screening tenants for your association in no time with our wide range of services.</p>
                    <ul class="icon-list">
                        <li>
                            <span class="icon-list__icon">
                                <img src="../assets/icons/icon-key.svg" alt="" />
                            </span>
                            Create you TE login
                        </li>
                        <li>
                            <span class="icon-list__icon">
                                <img src="../assets/icons/icon-gear.svg" alt="" />
                            </span>
                            Select your plan
                        </li>
                        <li>
                            <span class="icon-list__icon">
                                <img src="../assets/icons/icon-org.svg" alt="" />
                            </span>
                            Tell us about your organization
                        </li>
                        <li>
                            <span class="icon-list__icon">
                                <img src="../assets/icons/icon-trophy.svg" alt="" />
                            </span>
                            Agree and get started with TE
                        </li>
                    </ul>

                    <v-btn
                        @click="$router.push('sign-up')"
                        depressed
                        x-large
                        class="secondary"
                    >get started</v-btn>
                    <small class="alternate-option">Have you an account? <a href="">Sign In</a></small>
                </div>
            </div>
            <div class="intro-view__col">
                <div class="intro-view__media">
                    <img src="../assets/images/intruction-mobile.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Intro',
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.alternate-option {
    margin-top: 15px;
    display: block;
}

.intro-view {
    &__col {
        &:last-child {
            padding: 0;
            background: $primary-color;
        }
    }

    &__media {
        background: image('tenant-logo-bg.svg') top no-repeat;
        background-position-x: calc(100% + 50px);
        img {

        }
    }
}
</style>
